import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  title =" Hire Best Immigration Consultant Delhi | Best Immigration Consultant in India   "
  keyword='Best immigration consultant in india, immigration consultancy in delhi, study visa, visitor visa, business visa  '
  description=' P&R Immigration is one of the top immigration consultancy for Canada located in Delhi. To know more about visa process contacts our immigration experts.  Call us Today @91 9654335899  '

  constructor(private router: Router,
    private titleService:Title,
    private meta:Meta,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'header',title:'Best Immigration Consultant in India  | Hire Best Immigration Consultant Delhi ' })
    this.meta.addTag({name:'header', keyword:'Best immigration consultant in india, immigration consultancy in delhi, study visa, visitor visa, business visa' })
    this.meta.addTag({name:'header', description:' P&R Immigration is one of the top immigration consultancy for Canada located in Delhi. To know more about visa process contacts our immigration experts.  Call us Today @91 9654335899'})
  }
}






