import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rcic-link',
  templateUrl: './rcic-link.component.html',
  styleUrls: ['./rcic-link.component.css']
})
export class RcicLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
