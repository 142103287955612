import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import{ Routes, RouterModule } from '@angular/router'
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, }    from '@angular/common/http';

import { AppComponent } from './app.component';
import { Injectable } from '@angular/core';
import{ LazyLoadingModule} from './lazy-loading/lazy-loading.module'
import { HeaderComponent } from './header/header.component';
import { AssessmentFormComponent } from './study/assessment-form/assessment-form.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SponsorshipComponent } from './sponsorship/sponsorship.component';
import { WorkComponent } from './work/work.component';
import { ExpressEntryComponent } from './express-entry/express-entry.component';
import { PnpComponent } from './pnp/pnp.component';
import { VisitComponent } from './visit/visit.component';
import { StudyComponent } from './study/study.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { CostToStudyInCanadaComponent } from './cost-to-study-in-canada/cost-to-study-in-canada.component';
import { AdmissionRequirementsComponent } from './admission-requirements/admission-requirements.component';
import { OnlineApplicationGuideComponent } from './online-application-guide/online-application-guide.component';
import { ScholarshipsFinancialAidsComponent } from './scholarships-financial-aids/scholarships-financial-aids.component';
import { TopUniversitiesInCanadaComponent } from './top-universities-in-canada/top-universities-in-canada.component';
import { WhatToStudyComponent } from './what-to-study/what-to-study.component';
import { FranchiseComponent } from './franchise/franchise.component';
import { TeamComponent } from './team/team.component';
import { IeltsToClbComponent } from './ielts-to-clb/ielts-to-clb.component';
import { IccrcComponent } from './iccrc/iccrc.component';
import { CostEffectiveComponent } from './cost-effective/cost-effective.component';
import { LicencedComponent } from './licenced/licenced.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AlbertaProvincialNomineeProgramComponent } from './alberta-provincial-nominee-program/alberta-provincial-nominee-program.component';
import { BritishColumbiaPnpImmigrationComponent } from './british-columbia-pnp-immigration/british-columbia-pnp-immigration.component';
import{ StudyModule} from './study/study.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { CanadaFamilySponsorshipClassVisaComponent } from './canada-family-sponsorship-class-visa/canada-family-sponsorship-class-visa.component';
import { SpouseParentsSponsorshipVisaCanadaComponent } from './spouse-parents-sponsorship-visa-canada/spouse-parents-sponsorship-visa-canada.component';
import { SuperParentsVisaCanadaComponent } from './super-parents-visa-canada/super-parents-visa-canada.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { InvestorComponent } from './investor/investor.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SpecializeInCanadaEntrepreneurVisaComponent } from './specialize-in-canada-entrepreneur-visa/specialize-in-canada-entrepreneur-visa.component';
import { FindAJobComponent } from './find-a-job/find-a-job.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ResumeWritingComponent } from './resume-writing/resume-writing.component';
import { ResumeMarketingComponent } from './resume-marketing/resume-marketing.component';
import { StudyAssessmentFormComponent } from './study-assessment-form/study-assessment-form.component';
import { ApplyNowComponent } from './apply-now/apply-now.component';
import { ApplicantRegistrationComponent } from './applicant-registration/applicant-registration.component';
import { QuebecImmigrantInvestorProgramComponent } from './quebec-immigrant-investor-program/quebec-immigrant-investor-program.component';
import { ResidencyByInvestmentComponent } from './residency-by-investment/residency-by-investment.component';
import { SevenReasonsStudyInCanadaComponent } from './seven-reasons-study-in-canada/seven-reasons-study-in-canada.component';
import { NovaScotiaProvincialNomineeProgramComponent } from './nova-scotia-provincial-nominee-program/nova-scotia-provincial-nominee-program.component';
import { OntarioProvincialNomineeProgramComponent } from './ontario-provincial-nominee-program/ontario-provincial-nominee-program.component';
import { SaskatchewanProvincialNomineeProgramComponent } from './saskatchewan-provincial-nominee-program/saskatchewan-provincial-nominee-program.component';
import { TahnkYouComponent } from './tahnk-you/tahnk-you.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ManitobaProvincialNomineeProgramComponent } from './manitoba-provincial-nominee-program/manitoba-provincial-nominee-program.component';
import { OwnerOperatorProgramComponent } from './owner-operator-program/owner-operator-program.component';
import { FooterBottomComponent } from './footer-bottom/footer-bottom.component';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { SocialLinkComponent } from './social-link/social-link.component';
import { FeaturesLinkComponent } from './features-link/features-link.component';
import { RcicLinkComponent } from './rcic-link/rcic-link.component';
import { TabsComponent } from './tabs/tabs.component';
import { CountNumberComponent } from './count-number/count-number.component';
import { SliderTopComponent } from './slider-top/slider-top.component';
import { AboutTopComponent } from './about-top/about-top.component';
import { RotateProcessComponent } from './rotate-process/rotate-process.component';
import { SlideShowComponent } from './slide-show/slide-show.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { from } from 'rxjs';
import { BlogComponent } from './blog/blog.component';
import { NewsComponent } from './news/news.component';
import { ReadComponent } from './blog/read/read.component';
import { ViewComponent } from './news/view/view.component';
import { EllipsisPipe } from './ellipsis.pipe';
import { StartUpVisaComponent } from './start-up-visa/start-up-visa.component';
import { format } from 'url';
import { DataService } from './data.service';
const routes:Routes = [
]
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AssessmentFormComponent,
    AboutUsComponent,
    SponsorshipComponent,
    WorkComponent,
    ExpressEntryComponent,
    PnpComponent,
    VisitComponent,
    StudyComponent,
    AssessmentComponent,
    CostToStudyInCanadaComponent,
    AdmissionRequirementsComponent,
    OnlineApplicationGuideComponent,
    ScholarshipsFinancialAidsComponent,
    TopUniversitiesInCanadaComponent,
    WhatToStudyComponent,
    FranchiseComponent,
    TeamComponent,
    EllipsisPipe,
    IeltsToClbComponent,
    IccrcComponent,
    CostEffectiveComponent,
    LicencedComponent,
    ContactUsComponent,
    AlbertaProvincialNomineeProgramComponent,
    BritishColumbiaPnpImmigrationComponent,
    CanadaFamilySponsorshipClassVisaComponent,
    SpouseParentsSponsorshipVisaCanadaComponent,
    SuperParentsVisaCanadaComponent,
    
    AboutComponent,
    FaqComponent,
    InvestorComponent,
    GalleryComponent,
    SpecializeInCanadaEntrepreneurVisaComponent,
    FindAJobComponent,
    TermsConditionComponent,
    ResumeWritingComponent,
    ResumeMarketingComponent,
    StudyAssessmentFormComponent,
    ApplyNowComponent,
    ApplicantRegistrationComponent,
    QuebecImmigrantInvestorProgramComponent,
    ResidencyByInvestmentComponent,
    SevenReasonsStudyInCanadaComponent,
    NovaScotiaProvincialNomineeProgramComponent,
    OntarioProvincialNomineeProgramComponent,
    SaskatchewanProvincialNomineeProgramComponent,
    TahnkYouComponent,
    ThankYouComponent,
    ManitobaProvincialNomineeProgramComponent,
    OwnerOperatorProgramComponent,
    FooterBottomComponent,
    NavbarMenuComponent,
    SocialLinkComponent,
    FeaturesLinkComponent,
    RcicLinkComponent,
    TabsComponent,
    CountNumberComponent,
    SliderTopComponent,
    AboutTopComponent,
    RotateProcessComponent,
    SlideShowComponent,
    SitemapComponent,
    BlogComponent,
    NewsComponent,
    ReadComponent,
    ViewComponent,
    StartUpVisaComponent,
  ],
  imports: [
   
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    LazyLoadingModule,
    StudyModule,
    NgxPaginationModule,
    RouterModule.forRoot(routes ,{ scrollPositionRestoration: 'enabled',
    

  })
    
  ],
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
