import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-social-link',
  templateUrl: './social-link.component.html',
  styleUrls: ['./social-link.component.css']
})
export class SocialLinkComponent implements OnInit {

  blogDatakeys:any;
  constructor(private http: HttpClient, public router: Router) { }
  blogData: any;
  newsData: any;
  newsDatakeys: any;
  ngOnInit(): void {
   
    this.getnewdata();
    this.getLatestBlog();
  }
  getnewdata() {
    this.http.get('https://www.pnrimmigration.ca/api/public/api/news').subscribe(data => {
      this.newsData = data;
      this.newsDatakeys = Object.keys(data);
      var length = this.newsDatakeys.length;
      this.newsDatakeys = this.newsDatakeys.slice(length > 2 ? length - 3 : 0, length > 2 ? length - 1 : length);
    });
  }
  getLatestBlog() {
    this.http.get('https://www.pnrimmigration.ca/api/public/api/blog').subscribe(data => {
      this.blogData = data;
      this.blogDatakeys = Object.keys(data);
      var length = this.blogDatakeys.length;
      this.blogDatakeys = this.blogDatakeys.slice(length > 2 ? length - 3 : 0, length > 2 ? length - 1 : length);
    });
  }
}
