import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ontario-provincial-nominee-program',
  templateUrl: './ontario-provincial-nominee-program.component.html',
  styleUrls: ['./ontario-provincial-nominee-program.component.css']
})
export class OntarioProvincialNomineeProgramComponent implements OnInit {

  title ='Ontario Immigration Nominee Program | OINP | Ontario PNP  '
  keyword='ontario immigration nominee program, oinp, ontario pnp, ontario express entry, ontario immigrant nominee program  '
    description=   'One of the very popular province of Canada is Ontario. The Ontario PNP is intended to invite prospective immigrants who are qualified to drive the economic growth. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'Ontario PNP ' , keyword:'ontario immigration nominee program, oinp, ontario pnp, ontario express entry, ontario immigrant nominee program'})

this.meta.addTag({name:'Ontario PNP ' ,description:'One of the very popular province of Canada is Ontario. The Ontario PNP is intended to invite prospective immigrants who are qualified to drive the economic growth. Call us Today @91-9654335899'})
  }

}