import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-british-columbia-pnp-immigration',
  templateUrl: './british-columbia-pnp-immigration.component.html',
  styleUrls: ['./british-columbia-pnp-immigration.component.css']
})
export class BritishColumbiaPnpImmigrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
