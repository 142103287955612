import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-licenced',
  templateUrl: './licenced.component.html',
  styleUrls: ['./licenced.component.css']
})
export class LicencedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
