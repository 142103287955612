import { Component, OnInit } from '@angular/core';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  title =' About P&R Immigration Services | Top Visa Consultancy Service  '
keyword='immigration and visa consultants, immigration agent, immigration consultants, immigration lawyer, immigration experts, top visa consultancy services'
  description='P&R immigration is one of the leading immigration consultants in India; we endeavor to provide One Stop Solution to all our clients. Call us Today @91-9654335899 '
  
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'about-us' , title:'About P&R Immigration Services | Top Visa Consultancy Service'})
this.meta.addTag({name:'about-us' ,keyword:'immigration and visa consultants, immigration agent, immigration consultants, immigration lawyer, immigration experts, top visa consultancy services'})

this.meta.addTag({name:'about-us' ,description:'P&R immigration is one of the leading immigration consultants in India; we endeavor to provide One Stop Solution to all our clients. Call us Today @91-9654335899'})
  }
}
