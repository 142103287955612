import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-marketing',
  templateUrl: './resume-marketing.component.html',
  styleUrls: ['./resume-marketing.component.css']
})
export class ResumeMarketingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
