import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admission-requirements',
  templateUrl: './admission-requirements.component.html',
  styleUrls: ['./admission-requirements.component.css']
})
export class AdmissionRequirementsComponent implements OnInit {

  title =' Admission Requirement-Study in Canada | Admission Requiremnts for Collage  '
  keyword='admission requirements, admission requirements for collage, best consultancy for students '
    description='We at international academy provide some details about admission/entry requirements to study Canada. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'admission requirement' ,
keyword:'admission requirements, admission requirements for collage, best consultancy for students'})

this.meta.addTag({name:'admission requirement' ,description:'We at international academy provide some details about admission/entry requirements to study Canada. Call us Today @91-9654335899'})
  }

}
