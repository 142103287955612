import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.css']
})
export class VisitComponent implements OnInit {

  title ='Canada Tourist Visa How to Apply for Canada Tourist Visa | How To Can Apply Tourist Visa  '
  keyword='canada tourist visa, visit visa, canada tourist visa from india '
    description=   'If you have already planned to visit Canada, you must know the basic requirements to apply for a Tourist Visa. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
  
    this.meta.addTag({name:'visit' , title:'Canada Tourist Visa How to Apply for Canada Tourist Visa'})
this.meta.addTag({name:'visit' , keyword:'canada tourist visa, visit visa, canada tourist visa from india'})

this.meta.addTag({name:'visit' ,description:'If you have already planned to visit Canada, you must know the basic requirements to apply for a Tourist Visa. Call us Today @91-9654335899'})
  }

}