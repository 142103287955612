import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  title ='Contact with Best Immigration Consultant India | P&R Immigration  '
  keyword='best immigration consultant, hire best immigration consultancy delhi, top immigration consultants in india, contact with pnrimmigration  '
    description=   'Contact with Best Immigration Consultant Delhi, Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'contact' , keyword:'best immigration consultant, hire best immigration consultancy delhi, top immigration consultants in india, contact with pnrimmigration'})

this.meta.addTag({name:'contact' ,description:'Contact with Best Immigration Consultant Delhi, Call us Today @91-9654335899'})
  }

}