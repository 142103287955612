import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-pnp',
  templateUrl: './pnp.component.html',
  styleUrls: ['./pnp.component.css']
})
export class PnpComponent implements OnInit {

  title ='Canada Provincial Nominee Program | How the Provincial Nominee Program  '
  keyword='canada provincial nominee program, how the provincial nominee program, pnp in canada  '
    description=   'The province of Quebec does not have a provincial nominee program. See their immigration website to learn more about their programs. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'Provinicial Nominee Program ' , keyword:'canada provincial nominee program, how the provincial nominee program, pnp in canada'})

this.meta.addTag({name:'Provinicial Nominee Program ' ,description:'The province of Quebec does not have a provincial nominee program. See their immigration website to learn more about their programs. Call us Today @91-9654335899'})
  }

}