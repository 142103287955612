import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-top-universities-in-canada',
  templateUrl: './top-universities-in-canada.component.html',
  styleUrls: ['./top-universities-in-canada.component.css']
})
export class TopUniversitiesInCanadaComponent implements OnInit {

  title ='Top University in Canada 2019 | P&R Immigration  '
  keyword='top university in canada, best universities in canada, top university in canada 2019 '
    description=   'Ontario, British Columbia, Toronto, and Quebec are the top choices for international students, housing some of the best universities in Canada.  Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'top university' , keyword:'top university in canada, best universities in canada, top university in canada 2019'})

this.meta.addTag({name:'top university' ,description:'Ontario, British Columbia, Toronto, and Quebec are the top choices for international students, housing some of the best universities in Canada.  Call us Today @91-9654335899'})
  }

}
