import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-what-to-study',
  templateUrl: './what-to-study.component.html',
  styleUrls: ['./what-to-study.component.css']
})
export class WhatToStudyComponent implements OnInit {

  title ='How to Study in Canada | What to Study | P&R Immigration  '
  keyword='what to study, how to study in canada, what to study in canada 2019,   '
    description=   'Want to study in Canada? Read our guide to top universities in Canada, student cities, applications, costs, visas and more. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'what ro study' , keyword:'what to study, how to study in canada, what to study in canada 2019, '})

this.meta.addTag({name:'what ro study' ,description:'Want to study in Canada? Read our guide to top universities in Canada, student cities, applications, costs, visas and more. Call us Today @91-9654335899'})
  }

}
