import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-scholarships-financial-aids',
  templateUrl: './scholarships-financial-aids.component.html',
  styleUrls: ['./scholarships-financial-aids.component.css']
})
export class ScholarshipsFinancialAidsComponent implements OnInit {

  title ='Finance Scholarship in Canada | P&R Immigration  '
  keyword='finance scholarship in canada, scholarship to study in canada, scholarship in canada 2019  '
    description=   'This site provides information on scholarships for Canadian students who wish to study abroad and for international students who wish to study in Canada. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'scholarship-financial' , keyword:'finance scholarship in canada, scholarship to study in canada, scholarship in canada 2019 '})

this.meta.addTag({name:'scholarship-financial' ,description:'This site provides information on scholarships for Canadian students who wish to study abroad and for international students who wish to study in Canada. Call us Today @91-9654335899'})
  }

}
