import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-alberta-provincial-nominee-program',
  templateUrl: './alberta-provincial-nominee-program.component.html',
  styleUrls: ['./alberta-provincial-nominee-program.component.css']
})
export class AlbertaProvincialNomineeProgramComponent implements OnInit {

  title ='Alberta Provincial Nominee Program | Alberta PNP | AINP  '
  keyword='alberta provncial nominee program, alberta pnp, ainp, alberta express netry, alberta immigrant nominee program  '
    description=   ' Immigrate to Canada through the Alberta Immigrant Nominee Program (AINP) offers a valuable route. AINP is an economic immigration program operated by the government. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'Alberta PNP' ,title:'Alberta Provincial Nominee Program | Alberta PNP | AINP'})
this.meta.addTag({name:'Alberta PNP' , keyword:'alberta provncial nominee program, alberta pnp, ainp, alberta express netry, alberta immigrant nominee program'})

this.meta.addTag({name:'Alberta PNP' ,description:'Immigrate to Canada through the Alberta Immigrant Nominee Program (AINP) offers a valuable route. AINP is an economic immigration program operated by the government. Call us Today @91-9654335899'})
  }

}