import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-online-application-guide',
  templateUrl: './online-application-guide.component.html',
  styleUrls: ['./online-application-guide.component.css']
})
export class OnlineApplicationGuideComponent implements OnInit {

  title =' Canada Visa Application Online | P&R Immigration  '
  keyword='canada visa process time, canada visa application online, online application for canada, canada visa  '
    description=   'Canadian visa application, requirements for citizens of India. Travel (tourist, business, etc.) visas to Canada from India. Apply online. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'online application' , keyword:'canada visa process time, canada visa application online, online application for canada, canada visa  '})

this.meta.addTag({name:'online application' ,description:'Canadian visa application, requirements for citizens of India. Travel (tourist, business, etc.) visas to Canada from India. Apply online. Call us Today @91-9654335899'})
  }

}
