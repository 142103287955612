import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-investor',
  templateUrl: './investor.component.html',
  styleUrls: ['./investor.component.css']
})
export class InvestorComponent implements OnInit {

  title ='Canada Investor Visa Program | Business Immigration to Canada   '
  keyword='canada investor visa program, business immigration to canada, business in canada  '
    description=   'Canada is dream destination to everybody if you want investment and set up your Business in Canada our qualified staff will help you. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'investor' , keyword:'canada investor visa program, business immigration to canada, business in canada'})

this.meta.addTag({name:'investor' ,description:'Canada is dream destination to everybody if you want investment and set up your Business in Canada our qualified staff will help you. Call us Today @91-9654335899'})
  }

}