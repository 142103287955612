import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  p: number = 1;
  sidebar: number = 1;
  newsData: any;
  newsDatakeys: any;
  myParam: string;
  mynews: any;
  mynewsDatakeys: any;
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) {
  }
  ngOnInit(): void {
    debugger;
    this.route.params.subscribe((params: Params) => this.myParam = params['id']);
    this.bindSinglenews(this.myParam);
    this.getLatestNew();
  }

  getLatestNew() {
    this.http.get('https://www.pnrimmigration.ca/api/public/api/news').subscribe(data => {
      this.newsData = data;
      this.newsDatakeys = Object.keys(data);
      var length = this.newsDatakeys.length;
      this.newsDatakeys = this.newsDatakeys.slice(length > 7 ? length - 7 : 0, length > 7 ? length - 1 : length);
    });
  }
  bindSinglenews(myParam: any){
    this.http.get('https://www.pnrimmigration.ca/api/public/api/news/'+myParam).subscribe(data => {
      debugger;
      this.mynewsDatakeys = Object.keys(data);
      this.mynews = data[this.mynewsDatakeys[0]];
      
    });
  }

}