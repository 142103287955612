import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canada-family-sponsorship-class-visa',
  templateUrl: './canada-family-sponsorship-class-visa.component.html',
  styleUrls: ['./canada-family-sponsorship-class-visa.component.css']
})
export class CanadaFamilySponsorshipClassVisaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
