import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { AssessmentFormComponent } from './study/assessment-form/assessment-form.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SponsorshipComponent } from './sponsorship/sponsorship.component';
import { WorkComponent } from './work/work.component';
import { ExpressEntryComponent } from './express-entry/express-entry.component';
import { PnpComponent } from './pnp/pnp.component';
import { VisitComponent } from './visit/visit.component';
import { StudyComponent } from './study/study.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { CostToStudyInCanadaComponent } from './cost-to-study-in-canada/cost-to-study-in-canada.component';
import { AdmissionRequirementsComponent } from './admission-requirements/admission-requirements.component';
import { OnlineApplicationGuideComponent } from './online-application-guide/online-application-guide.component';
import { ScholarshipsFinancialAidsComponent } from './scholarships-financial-aids/scholarships-financial-aids.component';
import { TopUniversitiesInCanadaComponent } from './top-universities-in-canada/top-universities-in-canada.component';
import { WhatToStudyComponent } from './what-to-study/what-to-study.component';
import { FranchiseComponent } from './franchise/franchise.component';
import { TeamComponent } from './team/team.component';
import { IeltsToClbComponent } from './ielts-to-clb/ielts-to-clb.component';
import { IccrcComponent } from './iccrc/iccrc.component';
import { CostEffectiveComponent } from './cost-effective/cost-effective.component';
import { LicencedComponent } from './licenced/licenced.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AlbertaProvincialNomineeProgramComponent } from './alberta-provincial-nominee-program/alberta-provincial-nominee-program.component';
import { BritishColumbiaPnpImmigrationComponent } from './british-columbia-pnp-immigration/british-columbia-pnp-immigration.component';
import { CanadaFamilySponsorshipClassVisaComponent } from './canada-family-sponsorship-class-visa/canada-family-sponsorship-class-visa.component';
import { SpouseParentsSponsorshipVisaCanadaComponent } from './spouse-parents-sponsorship-visa-canada/spouse-parents-sponsorship-visa-canada.component';
import { SuperParentsVisaCanadaComponent } from './super-parents-visa-canada/super-parents-visa-canada.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { InvestorComponent } from './investor/investor.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SpecializeInCanadaEntrepreneurVisaComponent } from './specialize-in-canada-entrepreneur-visa/specialize-in-canada-entrepreneur-visa.component';
import { FindAJobComponent } from './find-a-job/find-a-job.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ResumeWritingComponent } from './resume-writing/resume-writing.component';
import { ResumeMarketingComponent } from './resume-marketing/resume-marketing.component';
import { StudyAssessmentFormComponent } from './study-assessment-form/study-assessment-form.component';
import { ApplyNowComponent } from './apply-now/apply-now.component';
import { ApplicantRegistrationComponent } from './applicant-registration/applicant-registration.component';
import { QuebecImmigrantInvestorProgramComponent } from './quebec-immigrant-investor-program/quebec-immigrant-investor-program.component';
import { ResidencyByInvestmentComponent } from './residency-by-investment/residency-by-investment.component';
import { SevenReasonsStudyInCanadaComponent } from './seven-reasons-study-in-canada/seven-reasons-study-in-canada.component';
import { NovaScotiaProvincialNomineeProgramComponent } from './nova-scotia-provincial-nominee-program/nova-scotia-provincial-nominee-program.component';
import { OntarioProvincialNomineeProgramComponent } from './ontario-provincial-nominee-program/ontario-provincial-nominee-program.component';
import { SaskatchewanProvincialNomineeProgramComponent } from './saskatchewan-provincial-nominee-program/saskatchewan-provincial-nominee-program.component';
import { TahnkYouComponent } from './tahnk-you/tahnk-you.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ManitobaProvincialNomineeProgramComponent } from './manitoba-provincial-nominee-program/manitoba-provincial-nominee-program.component';
import { OwnerOperatorProgramComponent } from './owner-operator-program/owner-operator-program.component';
import { FooterBottomComponent } from './footer-bottom/footer-bottom.component';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { NewsComponent } from './news/news.component';
import { ViewComponent } from './news/view/view.component';

import { BlogComponent } from './blog/blog.component';
import { ReadComponent } from './blog/read/read.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { StartUpVisaComponent } from './start-up-visa/start-up-visa.component';
 
const routes: Routes = [
  {path:'', redirectTo:'header',pathMatch:'full'},
  {path:'blog',component:BlogComponent},
  {path:'blog/read/:id',component:ReadComponent},
  {path:'news',component:NewsComponent},
  {path:'news/view/:id',component:ViewComponent},
  {path:'sitemap',component:SitemapComponent},
  {path:'owner-operator-program',component:OwnerOperatorProgramComponent},
  {path:'start-up-visa',component:StartUpVisaComponent},
  {path:'manitoba-provincial-nominee-program',component:ManitobaProvincialNomineeProgramComponent},
  {path:'manitoba-provincial-nominee-program',component:ManitobaProvincialNomineeProgramComponent},
  {path:'nova-scotia-provincial-nominee-program',component:NovaScotiaProvincialNomineeProgramComponent},
  {path:'seven-reasons-study-in-canada',component:SevenReasonsStudyInCanadaComponent},
  {path:'ontario-provincial-nominee-program',component:OntarioProvincialNomineeProgramComponent},
  {path:'residency-by-investment',component:ResidencyByInvestmentComponent},
  {path:'quebec-immigrant-investor-program',component:QuebecImmigrantInvestorProgramComponent},
  {path:'ielts-to-clb',component:IeltsToClbComponent},
  {path:'saskatchewan-provincial-nominee-program',component:SaskatchewanProvincialNomineeProgramComponent},
  {path:'applicant-registration',component:ApplicantRegistrationComponent},
  {path:'thank-you',component:ThankYouComponent},
  {path:'apply-now',component:ApplyNowComponent},
  {path:'study-assessment-form',component:StudyAssessmentFormComponent},
 {path:'resume-writing',component:ResumeWritingComponent},
{path:'resume-marketing',component:ResumeMarketingComponent},

  {path:'terms-condition',component:TermsConditionComponent},
   {path:'gallery',component:GalleryComponent},
   {path:'find-a-job',component:FindAJobComponent},
  {path:'specialize-in-canada-entrepreneur-visa',component:SpecializeInCanadaEntrepreneurVisaComponent},
  {path:'faq',component:FaqComponent},
  {path:'about',component:AboutComponent},
  {path:'investor',component:InvestorComponent},
  {path:'sponsorship',component:SponsorshipComponent},
  {path:'work',component:WorkComponent},
  {path:'contact-us',component:ContactUsComponent},
  {path:'express-entry',component:ExpressEntryComponent},
  {path:'pnp',component:PnpComponent},
  {path:'visit',component:VisitComponent},
  {path:'study',component:StudyComponent},
  {path:'assessment',component:AssessmentComponent},
  {path:'cost-to-study-in-canada',component:CostToStudyInCanadaComponent},
  {path:'admission-requirements',component:AdmissionRequirementsComponent},
  {path:'online-application-guide',component:OnlineApplicationGuideComponent},
  {path:'scholarships-financial-aids',component:ScholarshipsFinancialAidsComponent},
  {path:'top-universities-in-canada',component:TopUniversitiesInCanadaComponent},
  {path:'what-to-study',component:WhatToStudyComponent},
  {path:'franchise',component:FranchiseComponent},
  {path:'team',component:TeamComponent},
  {path:'iccrc',component:IccrcComponent},
  {path:'cost-effective',component:CostEffectiveComponent},
  {path:'licenced',component:LicencedComponent},
  {path:'canada-family-sponsorship-class-visa',component:CanadaFamilySponsorshipClassVisaComponent},
  {path:'spouse-parents-sponsorship-visa-canada',component:SpouseParentsSponsorshipVisaCanadaComponent},
  {path:'super-parents-visa-canada',component:SuperParentsVisaCanadaComponent},
  {path:'alberta-provincial-nominee-program',component:AlbertaProvincialNomineeProgramComponent},
  {path:'british-columbia-pnp-immigration',component:BritishColumbiaPnpImmigrationComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
