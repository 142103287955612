import { Component, OnInit } from '@angular/core';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-assessment-form',
  templateUrl: './assessment-form.component.html',
  styleUrls: ['./assessment-form.component.css']
})
export class AssessmentFormComponent implements OnInit {
  title='Free Online Immigration Enquiry Form | Assessment Form | P&R Immigration'
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
