import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cost-to-study-in-canada',
  templateUrl: './cost-to-study-in-canada.component.html',
  styleUrls: ['./cost-to-study-in-canada.component.css']
})
export class CostToStudyInCanadaComponent implements OnInit {

  title =' The Cost Studying in Canada | P&R Immigration  '
  keyword='the cost studying in canada, cost to study, study in canada, cost to study in canada'
    description='THE COST OF STUDYING IN CANADA. Compared to many countries, studying in Canada is very affordable. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'cost to study' ,
keyword:'the cost studying in canada, cost to study, study in canada, cost to study in canada '})

this.meta.addTag({name:'cost to study' ,description:'THE COST OF STUDYING IN CANADA. Compared to many countries, studying in Canada is very affordable. Call us Today @91-9654335899'})
  }

}
