import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.css']
})
export class StudyComponent implements OnInit {
  
  title =' Study in Canada | Canadian Student Visa | P&R Immigration  '
  keyword='study in canada, canadian student visa, canada student visa process  '
    description='Want to Study in Canada? Find out about universities, courses, admission, fees, visa requirements, work permit, cost of living etc. in Canada. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'study  in  canada' ,
keyword:'study in canada, canadian student visa, canada student visa process '})

this.meta.addTag({name:'study  in canada' ,description:'Want to Study in Canada? Find out about universities, courses, admission, fees, visa requirements, work permit, cost of living etc. in Canada. Call us Today @91-9654335899'})
  }

}
