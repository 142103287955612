import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-express-entry',
  templateUrl: './express-entry.component.html',
  styleUrls: ['./express-entry.component.css']
})
export class ExpressEntryComponent implements OnInit {

  title ='Canada Express Entry Visa Consultants, India | P&R Immigration  '
  keyword='canada express entry, express entry, express entry program, express entry system  '
    description= 'Canada express entry program handpicks the skilled immigrants. Canada express entry points are vital to pass through Canada express entry eligibility check. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'Express Entry' , keyword:'canada express entry, express entry, express entry program, express entry system'})

this.meta.addTag({name:'Express Entry' ,description:'Canada express entry program handpicks the skilled immigrants. Canada express entry points are vital to pass through Canada express entry eligibility check. Call us Today @91-9654335899'})
  }

}