import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-nova-scotia-provincial-nominee-program',
  templateUrl: './nova-scotia-provincial-nominee-program.component.html',
  styleUrls: ['./nova-scotia-provincial-nominee-program.component.css']
})
export class NovaScotiaProvincialNomineeProgramComponent implements OnInit {

  title ='Nova Scotia Provincial Nominee Program | Nova Scotia PNP  '
  keyword='nova scotia pnp, nova scotia, nova scotia provincial nominee program , nova scotia express entry, immigrate to nova scotia  '
    description=   'Applicants must have an confirmed full time job offer from a Nova Scotia employer before they can apply to the provincial nominee program. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'Nova Scotia' , keyword:'nova scotia pnp, nova scotia, nova scotia provincial nominee program , nova scotia express entry, immigrate to nova scotia'})

this.meta.addTag({name:'Nova Scotia' ,description:'Applicants must have an confirmed full time job offer from a Nova Scotia employer before they can apply to the provincial nominee program. Call us Today @91-9654335899'})
  }

}