import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-saskatchewan-provincial-nominee-program',
  templateUrl: './saskatchewan-provincial-nominee-program.component.html',
  styleUrls: ['./saskatchewan-provincial-nominee-program.component.css']
})
export class SaskatchewanProvincialNomineeProgramComponent implements OnInit {

  title ='Saskatchewan  Immigrant Nominee Program | Saskatchewan PNP | SINP  '
  keyword='saskatchewan immigrant nominee program, sinp, saskatchewan pnp, saskatchewan express entry  '
    description=   'If you wish to apply for SINP Program- 2019, first check your eligibility and documents requirements. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'SINP' , keyword:'saskatchewan immigrant nominee program, sinp, saskatchewan pnp, saskatchewan express entry'})

this.meta.addTag({name:'SINP' ,description:'If you wish to apply for SINP Program- 2019, first check your eligibility and documents requirements. Call us Today @91-9654335899'})
  }

}