import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {

  title ='How Do I Work In Canada | Find A Job In Canada | P&R Immigration  '
  keyword='Work in canada, how do I work in canada, find a job in canada, find jobs in canada  '
    description=   ' P&R Immigration is regt immigration consultant who has licensed to recruit people from India. You can use our connections within Canada to get a job.'
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'work' , title:'How Do I Work In Canada | Find A Job In Canada'})
this.meta.addTag({name:'work' , keyword:'Work in canada, how do I work in canada, find a job in canada, find jobs in canada'})

this.meta.addTag({name:'work' ,description:'P&R Immigration is regt immigration consultant who has licensed to recruit people from India. You can use our connections within Canada to get a job.'})
  }

}
