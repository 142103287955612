import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cost-effective',
  templateUrl: './cost-effective.component.html',
  styleUrls: ['./cost-effective.component.css']
})
export class CostEffectiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
