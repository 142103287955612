import { Injectable } from '@angular/core';
import{HttpClient} from '@angular/common/http';
  import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor( private http: HttpClient) { }

GetPost()
{
return this.http.get('https://www.pnrimmigration.ca/api/public/api/blog');
}
ngOnInit()
{
  
}
}