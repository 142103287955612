import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.css']
})
export class ReadComponent implements OnInit {
  p: number = 1;
  sidebar: number = 1;
  blogData: any;
  blogDatakeys: any;
  myParam: string;
  myblog: any;
  myblogDatakeys: any;
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) {
  }
  ngOnInit(): void {
    debugger;
    this.route.params.subscribe((params: Params) => this.myParam = params['id']);
    this.bindSingleblog(this.myParam);
    this.getLatestNew();
  }

  getLatestNew() {
    this.http.get('https://www.pnrimmigration.ca/api/public/api/blog').subscribe(data => {
      this.blogData = data;
      this.blogDatakeys = Object.keys(data);
      var length = this.blogDatakeys.length;
      this.blogDatakeys = this.blogDatakeys.slice(length > 7 ? length - 7 : 0, length > 7 ? length - 1 : length);
    });
  }
  bindSingleblog(myParam: any){
    this.http.get('https://www.pnrimmigration.ca/api/public/api/blog/'+myParam).subscribe(data => {
      debugger;
      this.myblogDatakeys = Object.keys(data);
      this.myblog = data[this.myblogDatakeys[0]];
      
    });
  }

}