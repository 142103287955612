import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-manitoba-provincial-nominee-program',
  templateUrl: './manitoba-provincial-nominee-program.component.html',
  styleUrls: ['./manitoba-provincial-nominee-program.component.css']
})
export class ManitobaProvincialNomineeProgramComponent implements OnInit {

  title ='Manitoba Provincial Nominee Program | MPNP  '
  keyword='manitoba provincial nominee program, mpnp, canada mpnp, manitoba expressentry, manitoba pnp requirements 2019  '
    description=   'Know what are the latest news, updates, rules and changes for Manitoba PNP Program requirements 2019. Find out Manitoba PNP eligibility 2019? Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'Manitoba PNP' , keyword:'manitoba provincial nominee program, mpnp, canada mpnp, manitoba expressentry, manitoba pnp requirements 2019'})

this.meta.addTag({name:'Manitoba PNP' ,description:'Know what are the latest news, updates, rules and changes for Manitoba PNP Program requirements 2019. Find out Manitoba PNP eligibility 2019? Call us Today @91-9654335899'})
  }

}