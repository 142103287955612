import { Component, OnInit } from '@angular/core';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-start-up-visa',
  templateUrl: './start-up-visa.component.html',
  styleUrls: ['./start-up-visa.component.css']
})
export class StartUpVisaComponent implements OnInit {

  title ='Canada Startup Visa Program | P&R Immigration'
  keyword='Start Up Visa program, canada startup visa program, canad entrepeneur visa program, canada startup visa, canada startup visa consultants  '
    description=   'The program encourages immigrant entrepreneurs to grow their companies in Canada. Successful applicants link with private sector organizations in Canada, where they can receive funding, guidance, and expertise in opening and operating their enterprise in Canada.    '
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'start-up-visa' , keyword:'Start Up Visa program, canada startup visa program, canad entrepeneur visa program, canada startup visa, canada startup visa consultants'})

this.meta.addTag({name:'start-up-visa' ,description:'The program encourages immigrant entrepreneurs to grow their companies in Canada. Successful applicants link with private sector organizations in Canada, where they can receive funding, guidance, and expertise in opening and operating their enterprise in Canada.'})
  }

}

