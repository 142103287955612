import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  title ='Study in Canada FAQ | Canada Student Visa FAQ  '
  keyword='study in canada faq, canada student visa faq, want to study in canada,   '
    description=   'P&R Immigration is the best consultant for Study in Canada. Get assistance for Canada Visa Information, immigration, and study visa frequently asked questions. Call us Today @91-9654335899    '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
this.meta.addTag({name:'faq' , keyword:'study in canada faq, canada student visa faq, want to study in canada, '})

this.meta.addTag({name:'faq study to canada' ,description:'P&R Immigration is the best consultant for Study in Canada. Get assistance for Canada Visa Information, immigration, and study visa frequently asked questions. Call us Today @91-9654335899'})
  }

}
