import { Component, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import {Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sponsorship',
  templateUrl: './sponsorship.component.html',
  styleUrls: ['./sponsorship.component.css']
})
export class SponsorshipComponent implements OnInit {
  title ='Canada Family Sponsorship Visa | P&R Immigration  '
  keyword='canada family sponsorship, canada family visa, family visa  '
    description=   'When you are on PR, you are eligible to call your dear one, your family with you on Canada Family Sponsorship Visa. Apply today and Sponsor your family '
    
  constructor(
    public router:Router,
    private titleService:Title,
    private meta:Meta,
  ) { }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
    this.meta.addTag({name:'visit' , title:'Canada Family Sponsorship Visa | P&R Immigration '})
this.meta.addTag({name:'visit' , keyword:'canada family sponsorship, canada family visa, family visa'})

this.meta.addTag({name:'visit' ,description:'When you are on PR, you are eligible to call your dear one, your family with you on Canada Family Sponsorship Visa. Apply today and Sponsor your family'})
  }
}