import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
 _time
  get time() {  return this._time; }

  title = 'Latest Immigration Visa Blog, Updates - Canada & Australia | pNr Immigration'
  blogData: any;
  blogDatakeys: any;
  latestblogDatakeys: any;
//initializing p to one
p: number = 1;
sidebar: number = 1;
  constructor(private http: HttpClient, public router: Router, ) { 
    this._time = Date.now();
    setInterval(() => {
      this._time = Date.now();
  }, 1);
  }
  ngOnInit(): void {
    this.getAllNew();
  }
  getAllNew() {
    this.http.get('https://www.pnrimmigration.ca/api/public/api/blog').subscribe(data => {
      this.blogData = data;
      this.blogDatakeys = Object.keys(data);
      this.blogDatakeys  = this.blogDatakeys;
      this.latestblogDatakeys =this.getAllNew;
      var length = this.blogDatakeys.length;
      this.latestblogDatakeys = this.blogDatakeys.slice(length > 20 ? length - 19 : 0, length > 20 ? length - 0 : length);
    });
    
  }
 

}


