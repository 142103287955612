import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  p: number = 1;
  sidebar: number = 1;
  title = 'Latest Immigration Visa news, Updates - Canada & Australia | P&R Immigration'
  newsData: any;
  newsDatakeys: any;
  latestnewsDatakeys: any;
  constructor(private http: HttpClient, public router: Router, ) { 
    
  }
  ngOnInit(): void {
    this.getAllNew();
  }
  getAllNew() {
    this.http.get('https://www.pnrimmigration.ca/api/public/api/news').subscribe(data => {
      this.newsData = data;
      this.newsDatakeys = Object.keys(data);
      this.newsDatakeys = this.newsDatakeys;

      var length = this.newsDatakeys.length;
      this.latestnewsDatakeys = this.newsDatakeys.slice(length > 6 ? length - 7 : 0, length > 6 ? length - 0 : length);
    });
  }
}