import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seven-reasons-study-in-canada',
  templateUrl: './seven-reasons-study-in-canada.component.html',
  styleUrls: ['./seven-reasons-study-in-canada.component.css']
})
export class SevenReasonsStudyInCanadaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
